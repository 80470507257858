module.exports = {
  name: 'eventsHistory',
  get url() {
    return process.env.REACT_APP_MICRO_FE_EVENTS
  },
  urlGlobalVariable: `eventsHistoryModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
