module.exports = {
  name: 'auth',
  get url() {
    return process.env.REACT_APP_MICRO_FE_AUTH
  },
  urlGlobalVariable: `authModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
