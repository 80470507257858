const modules = require('./modules')

module.exports = modules.map((module) => ({
  name: module.name,
  get url() {
    const hash = window.btoa(new Date())
    return `${module.url}?version=${hash}`
  },
  urlGlobalVariable: `${module.name}ModuleUrl`,
  get federationConfig() {
    if (module.vite) {
      return `promise import([window.${this.urlGlobalVariable}])`
    }

    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}))
