module.exports = {
  name: 'chargeback',
  get url() {
    return process.env.REACT_APP_MICRO_FE_CHARGEBACK
  },
  urlGlobalVariable: `chargebackModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
