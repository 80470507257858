module.exports = {
  name: 'accountLimitRequest',
  get url() {
    return process.env.REACT_APP_MICRO_FE_ACCOUNT_LIMIT
  },
  urlGlobalVariable: `accountLimitRequestModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
