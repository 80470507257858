module.exports = {
  name: 'faq',
  get url() {
    return process.env.REACT_APP_MICRO_FE_FAQ
  },
  urlGlobalVariable: `faqModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
