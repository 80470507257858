module.exports = {
  name: 'creditCardTimeline',
  get url() {
    return process.env.REACT_APP_MICRO_FE_CREDIT_CARD_TIMELINE
  },
  urlGlobalVariable: `creditCardTimelineModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
