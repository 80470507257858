module.exports = {
  name: 'cancelCreditcardAccount',
  get url() {
    const hash = window.btoa(new Date())
    return `${process.env.REACT_APP_MICRO_FE_CANCEL_CREDITCARD_ACC}?version=${hash}`
  },
  urlGlobalVariable: `cancelCreditcardAccountModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
