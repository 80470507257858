module.exports = {
  name: 'additionalHistoricList',
  get url() {
    return process.env.REACT_APP_MICRO_FE_ADDITIONAL_HISTORIC_LIST
  },
  urlGlobalVariable: `additionalHistoricListModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
