module.exports = {
  name: 'additionalpersondata',
  get url() {
    return process.env.REACT_APP_MICRO_FE_ADDITIONAL_PERSON_DATA
  },
  urlGlobalVariable: `additionalpersondataModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
