module.exports = [
  {
    name: 'referrals',
    url: process.env.REACT_APP_MICRO_FE_REFERRALS,
  },
  {
    name: 'referralsUpdate',
    url: process.env.REACT_APP_MICRO_FE_REFERRALS_UPDATE,
  },
  {
    name: 'overlimitToggle',
    url: process.env.REACT_APP_MICRO_FE_OVERLIMIT_TOGGLE,
  },
  {
    name: 'networkAuthorization',
    url: process.env.REACT_APP_MICRO_FE_NETWORK_AUTHORIZATION,
  },
  {
    name: 'blockAdditionalDevice',
    url: process.env.REACT_APP_MICRO_FE_BLOCKADDITIONALDEVICE,
  },
  {
    name: 'changePlan',
    url: process.env.REACT_APP_MICRO_FE_CHANGEPLAN,
  },
  {
    name: 'advanceInstallmentAgreement',
    url: process.env.REACT_APP_MICRO_FE_ADVANCE_INSTALLMENT_AGREEMENT,
    vite: true,
  },
  {
    name: 'reabAccount',
    url: process.env.REACT_APP_MICRO_FE_REAB_ACCOUNT,
    vite: true,
  },
  {
    name: 'portabilityCards',
    url: process.env.REACT_APP_MICRO_FE_PORTABILITY_CARDS,
    vite: true,
  },
  {
    name: 'carddetailsnewmf',
    url: process.env.REACT_APP_MICRO_FE_CARD_DETAILS,
    vite: true,
  },
  {
    name: 'changeDueDate',
    url: process.env.REACT_APP_MICRO_FE_CHANGE_DUE_DATE,
    vite: true,
  },
]
