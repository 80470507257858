import auth from 'remotes/modules/auth'
import cancelAccount from 'remotes/modules/cancelAccount'
import cancelCreditcardAccount from 'remotes/modules/cancelCreditcardAccount'
import faq from 'remotes/modules/faq'
import accessManagement from 'remotes/modules/accessManagement'
import cardProtected from 'remotes/modules/cardProtected'
import cardReissue from 'remotes/modules/cardReissue'
import cardList from 'remotes/modules/cardList'
import creditCardTimeline from 'remotes/modules/creditCardTimeline'
import accountLimitRequest from 'remotes/modules/accountLimitRequest'
import eventsHistory from 'remotes/modules/eventsHistory'
import customerInfos from 'remotes/modules/customerInfos'
import paymentAccount from 'remotes/modules/paymentAccount'
import guidancesEditor from 'remotes/modules/guidancesEditor'
import historyTickets from 'remotes/modules/historyTickets'
import trackingListMf from 'remotes/modules/trackingListMf'
import chargeback from 'remotes/modules/chargeback'
import resolveCreditBalance from 'remotes/modules/resolveCreditBalance'
import reverseToken from 'remotes/modules/reverseToken'
import additionalpersondata from 'remotes/modules/additionalPersonData'
import advanceInstallments from 'remotes/modules/advanceInstallments'
import additionalCardDetails from 'remotes/modules/additionalCardDetails'
import itauAvisa from 'remotes/modules/itauAvisa'
import annuityCreditcard from 'remotes/modules/annuityCreditcard'
import resolvePNP from 'remotes/modules/resolvePNP'
import additionalCardCancel from 'remotes/modules/additionalCardCancel'
import framingData from 'remotes/modules/framingData'
import rewardsProgram from 'remotes/modules/rewardsProgram'
import changeDueDate from 'remotes/modules/changeDueDate'

// Novo formato
import remotes from 'remotes/federation.config'
remotes.forEach((remote) => {
  window[remote.urlGlobalVariable] = remote.url
})

import additionalHistoricList from 'remotes/modules/additionalHistoricList'

window[auth.urlGlobalVariable] = auth.url
window[accessManagement.urlGlobalVariable] = accessManagement.url
window[cardProtected.urlGlobalVariable] = cardProtected.url
window[faq.urlGlobalVariable] = faq.url
window[cancelAccount.urlGlobalVariable] = cancelAccount.url
window[cancelCreditcardAccount.urlGlobalVariable] = cancelCreditcardAccount.url
window[chargeback.urlGlobalVariable] = chargeback.url
window[cardReissue.urlGlobalVariable] = cardReissue.url
window[cardList.urlGlobalVariable] = cardList.url
window[creditCardTimeline.urlGlobalVariable] = creditCardTimeline.url
window[accountLimitRequest.urlGlobalVariable] = accountLimitRequest.url
window[eventsHistory.urlGlobalVariable] = eventsHistory.url
window[customerInfos.urlGlobalVariable] = customerInfos.url
window[paymentAccount.urlGlobalVariable] = paymentAccount.url
window[guidancesEditor.urlGlobalVariable] = guidancesEditor.url
window[historyTickets.urlGlobalVariable] = historyTickets.url
window[trackingListMf.urlGlobalVariable] = trackingListMf.url
window[resolveCreditBalance.urlGlobalVariable] = resolveCreditBalance.url
window[reverseToken.urlGlobalVariable] = reverseToken.url
window[additionalpersondata.urlGlobalVariable] = additionalpersondata.url
window[advanceInstallments.urlGlobalVariable] = advanceInstallments.url
window[additionalCardDetails.urlGlobalVariable] = additionalCardDetails.url
window[itauAvisa.urlGlobalVariable] = itauAvisa.url
window[annuityCreditcard.urlGlobalVariable] = annuityCreditcard.url
window[resolvePNP.urlGlobalVariable] = resolvePNP.url
window[additionalCardCancel.urlGlobalVariable] = additionalCardCancel.url
window[framingData.urlGlobalVariable] = framingData.url
window[additionalHistoricList.urlGlobalVariable] = additionalHistoricList.url
window[rewardsProgram.urlGlobalVariable] = rewardsProgram.url
window[changeDueDate.urlGlobalVariable] = changeDueDate.url

import('./bootstrap')