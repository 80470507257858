module.exports = {
  name: 'rewardsProgram',
  get url() {
    return `${process.env.REACT_APP_MICRO_FE_REWARDS_PROGRAM}`
  },
  urlGlobalVariable: `rewardsProgramModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
