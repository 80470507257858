module.exports = {
  name: 'cardReissue',
  get url() {
    return process.env.REACT_APP_MICRO_FE_CARD_REISSUE
  },
  urlGlobalVariable: `cardReissueModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
