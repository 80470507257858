module.exports = {
  name: 'resolvePNP',
  get url() {
    return process.env.REACT_APP_MICRO_FE_RESOLVE_PNP
  },
  urlGlobalVariable: `resolvePNPModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
