module.exports = {
  name: 'framingData',
  get url() {
    const hash = window.btoa(new Date())
    return `${process.env.REACT_APP_MICRO_FE_FRAMING_DATA}?version=${hash}`
  },
  urlGlobalVariable: `framingDataModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
