module.exports = {
  name: 'cardProtected',
  get url() {
    return process.env.REACT_APP_MICRO_FE_CARD_PROTECTED
  },
  urlGlobalVariable: `cardProtectedModuleUrl`,
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]`
  },
}
